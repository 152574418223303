import { BrowserTracing } from "@sentry/tracing";

const defaultConfig = {
  dsn: "https://2515d59047eea9e4f2209ee40d629191@o4505798367379456.ingest.sentry.io/4505798380879872",
  integrations: [new BrowserTracing()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};

export const clientConfig = () => ({
  ...defaultConfig,
});

export const serverConfig = () => ({
  ...defaultConfig,
});

export const edgeConfig = () => ({
  ...defaultConfig,
});
